import React from 'react';

import Layout from '../components/layout';
import Nav from '../components/nav';
import Footer from '../components/footer';
import Slogan from '../components/slogan';
import Contact from '../components/contact';
import SEO from '../components/seo';

import '../scss/attorno.scss';

const WaaromAttorno = () => (
  <Layout>
    <SEO
      title="Bedrijfsfeesten"
      description="Heeft u een evenement te organiseren? Bedrijfsrecepties,
      opendeurdagen, personeelsfeesten of gewoon een bedrijfslunch?
      Attorno staat 24/7 voor u beschikbaar! Wij zijn ervan overtuigd
      dat ons concept erin slaagt uw bedrijfsfeest de uitstraling en
      meerwaarde te geven die u voor ogen hebt!"
    />

    <Nav />

    <header
      className="intro-block py-3 cover-bg"
      style={{ backgroundImage: 'url(\'design/img/header/oven.jpg\')' }}
    >
      <div className="container">
        <Slogan heading="Mobiel pizzakraam op je bedrijfsfeest?" />
      </div>
    </header>

    <section
      className="pt-8 pb-3 text-center pattern-bg"
      style={{ backgroundImage: 'url(/design/img/pattern-background.png)' }}
    >
      <div className="container">
        <div className="row no-dev">
          <div className="col-sm-8 col-sm-offset-2 sep-bottom editContent">
            <h2 className="title">Een orgineel bedrijfsfeest organiseren?</h2>
            <p>
              Heeft u een evenement te organiseren? Bedrijfsrecepties,
              opendeurdagen, personeelsfeesten of gewoon een bedrijfslunch?
              Attorno staat 24/7 voor u beschikbaar! Wij zijn ervan overtuigd
              dat ons concept erin slaagt uw bedrijfsfeest de uitstraling en
              meerwaarde te geven die u voor ogen hebt!
            </p>
          </div>
          <div className="col-sm-12">
            <img
              src="/design/img/center-block-background.png"
              className="screen"
              alt="Authentieke pizza oven"
            />{' '}
          </div>
        </div>
      </div>
    </section>
    <section
      className="pt-4 pb-6 pattern-bg"
      style={{ backgroundImage: 'url(/design/img/pattern-background.png)' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-10 col-sm-offset-1">
            <h2 className="text-center mb-3">Concept</h2>
            <div className="row mb-4 eh-grid">
              <div className="col-sm-6 col-md-5 col-md-offset-1 pull-right">
                <img src="/design/img/teaser/aanpak.jpg" alt="Pizza aanpak" />
              </div>
              <div className="col-sm-6">
                <h3 className="mt-0">Aanpak</h3>
                <p>
                  Klantvriendelijkheid en topkwaliteit van onze ingrediënten
                  zijn onze belangrijkste waarden!
                  <br />
                  Ons personeel heeft altijd een interne opleiding genoten,
                  waarbij de focus ligt op de omgang met klanten.
                  <br />
                  De gebruikte ingrediënten zijn altijd vers en het deeg wordt
                  pas kort op voorhand bereid en in onze ovens voorgebakken. Op
                  uw feest worden de voorgebakken pizza’s belegd en in onze
                  Italiaanse houtoven(s) afgebakken. De combinatie van de
                  voorgebakken pizzabodems met de houtoven(s) van 300 – 400
                  graden zorgt dat een pizza op 1 minuut volledig doorbakken is!
                </p>
              </div>
            </div>

            <div className="row mb-4 eh-grid">
              <div className="col-sm-6">
                <img
                  src="/design/img/teaser/zelfs-grotere-feesten.jpg"
                  alt="Meerdere pizza's"
                />
              </div>
              <div className="col-sm-6">
                <h3>Zelfs grotere feesten</h3>
                <p>
                  Afhankelijk van het aantal genodigden nemen wij 1 of meerdere
                  mobiele houtovens mee. Op geregelde basis organiseren wij
                  feesten met 300 deelnemers of meer. Dit loopt gesmeerd. Bij
                  een feest met 3 houtovens (voor een 150-tal genodigden),
                  kunnen er elke minuut 6 volledige pizza’s, en dit van diverse
                  soorten voorgeschoteld worden aan uw genodigden.
                </p>
              </div>
            </div>

            <div className="row mb-4 eh-grid">
              <div className="col-sm-6 pull-right">
                <img
                  src="/design/img/teaser/voor-en-nagerechten.jpg"
                  alt="Voorgerechten"
                />
              </div>
              <div className="col-sm-6">
                <h3>Voor- en nagerechten</h3>
                <p>
                  Indien u dat wenst, kunnen wij uw bedrijfsevent ook voorzien
                  van voor- en nagerechten. Deze gaan van koude Italiaanse
                  specialiteiten tot vers gemaakte tiramisu en/of
                  chocolademousse. Veel is mogelijk via overleg!
                </p>
              </div>
            </div>

            <div className="text-center">
              <h3 className="mt-7 mb-0">Locatie</h3>
              <div className="row">
                <div className="col-sm-8 col-sm-offset-2">
                  <p>
                    Wij komen over het hele land. Pizzafeesten in de Ardennen of
                    aan de kust behoren tot onze normale gang van zaken. Probeer
                    het eens uit. Velen die u voorgingen hebben ons ondertussen
                    opnieuw gecontacteerd.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Contact />

    <Footer />
  </Layout>
);

export default WaaromAttorno;
